.menu-image{
    display: flex;
    justify-content: center;
}

.menu-img{
    max-width: 30%;
    padding: 2rem;
}

@media (max-width: 768px) {
    .menu-image{
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .menu-img{
        max-width: 80%;
    }
    
}

