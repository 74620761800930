.desc {
    margin: 0px auto;
    width: 50%;
}

@media (max-width: 768px) {
    .desc {
        margin: 0px auto;
        width: 50%;
    }

}