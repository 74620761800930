.order_card {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.order_card h8 {
  font-size: 1.5rem;
  font-weight: bold;
}

.order_card h10 {
  font-size: 1.2rem;
  margin-top: 20px;
}

.order_card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.order_card .option_list {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.order_card .option_list p {
  margin: 5px 0;
 
}

.order_card .order_options {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: small;
}

.order_card .order_options li {
  margin: 5px 0;
}

.order_card .order_options li::before {
  content: "•";
  color: #333;
  margin-right: 5px;
}

.order_card .order_options li:last-child::before {
  content: "";
}

.order_card .total_price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
}
