.order-container {
  max-width: 50%;
  margin: auto;
  padding: 20px;
}

.error-message {
  color: red;
}

.field {
  margin-bottom: 20px;
}

.submitButton {
  margin-top: 10px;
}