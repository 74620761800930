.item-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 32px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item-image {
  width: 50%;
  margin-top: 20px;
  border-radius: 8px;
}

.item-description {
  margin-top: 20px;
  text-align: center;
}

.price-info {
  margin-top: 20px;
  text-align: center;
}

.options-section {
  margin-top: 20px;
  width: 100%;
}

.quantity-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.quantity-label {
  margin-right: 8px;
}

.total-info {
  margin-top: 20px;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.actions button {
  margin: 0 8px;
  border-radius: 20px;
  padding: 10px 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}