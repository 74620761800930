.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #030404;
  width: 100%;
  /* Add more styling as needed */
}

.horizontal-menu {
  display: flex;
  list-style-type: none;
}

.horizontal-menu li {
  margin-right: 1.5rem;
}


.horizontal-menu li a {
  text-decoration: none;
  position: relative;
  color: #ffff;
}

.horizontal-menu li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #bf1f26;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.horizontal-menu li a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.logo {
  width: 20%;
  height: 20%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  :hover{
    cursor: pointer;
  }
}

/* Your existing styles for .navbar and .horizontal-menu go here */

.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #ffff;
  margin: 3px 0;
}

@media (max-width: 768px) {
  .burger-menu {
    display: flex;
    padding-left: 16px;
  }

  .logo {
    width: 30%;
    height: 30%;
  }
  .horizontal-menu {
    display: none;
  }

  .horizontal-menu li {
    margin-right: 0.5rem;
  }

  .horizontal-menu li:last-child {
    margin-right: 0; 
  }

  .horizontal-menu.active {
    display: flex;
    margin: 16px;
  }

  .burger-menu.active + .logo {
    display: none;
  }
}
