.menu-item {
  color: black;
  padding: 1.5rem;
}
.item-img {
  max-height: 30%;
}
.menu-item-card {
  color: black;
  padding-bottom: 2rem;
  border-radius: 12px !important;
}
